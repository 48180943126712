// ? These components function with alpine + tailwind UI components
// import Swiper, {Navigation, Pagination, Mousewheel, EffectCreative, Autoplay, FreeMode} from 'swiper'

import Swiper from 'swiper';
import {Navigation, Pagination, Mousewheel, EffectCreative, Autoplay, FreeMode} from 'swiper/modules';

window.obaia.Components = {}

    window.obaia.Components.marquee = () => {
        let animationFrameId = null
        let shouldAnimate = false
        const throttleDelay = 33 // Throttle delay (approximately 33 = 30FPS, 16 = 60FPS)

        return {
            posX: 0,
            marqueeSpeed: 200, // Speed of the marquee (pixels per second)
            marqueeElement: null,
            marqueeWidth: 0,
            contentWidth: 0,
            clone: null,
            cloneCount: 0,
            logoItem: null,
            logoWidth: null,

            init() {
                this.marqueeElement = this.$refs.marquee
                this.marqueeWidth = this.marqueeElement.offsetWidth
                this.contentWidth = this.marqueeElement.scrollWidth

                this.logoItem = this.marqueeElement.querySelector('.js-logo-item')
                this.logoWidth = this.logoItem.offsetWidth
                // Wait for DOMContentLoaded event
                // Call handleResize initially to set up the marquee
                // document.addEventListener('DOMContentLoaded', () => console.log('DOC LOADED'))
                this.handleResize()
            },

            startAnimation() {
                if (animationFrameId) {
                    cancelAnimationFrame(animationFrameId)
                }

                const animate = () => {
                    // this.moveMarquee()
                    let isAnimating = false

                    function throttle(callback, delay) {
                        let timeoutId = null

                        return function () {
                            if (!isAnimating) {
                                isAnimating = true
                                callback.apply(this, arguments)

                                timeoutId = setTimeout(() => {
                                    isAnimating = false
                                }, delay)
                            }
                        }
                    }

                    // Wrap the moveMarquee function with the throttle function
                    const throttledMoveMarquee = throttle(this.moveMarquee.bind(this), throttleDelay) // Adjust the delay as needed (e.g., 16ms for approximately 60 FPS)

                    // Call the throttledMoveMarquee function instead of moveMarquee in the animation loop
                    throttledMoveMarquee()

                    animationFrameId = requestAnimationFrame(animate)
                }

                animationFrameId = requestAnimationFrame(animate)
            },

            stopAnimation() {
                cancelAnimationFrame(animationFrameId)
            },

            moveMarquee() {
                this.posX -= this.marqueeSpeed / 100

                if (this.posX < -this.contentWidth) {
                    this.posX += this.contentWidth
                }

                this.marqueeElement.style.transform = `translate3d(${this.posX}px,0,0)`
            },

            handleResize() {
                const viewportWidth = window.innerWidth
                const shouldAnimateNew =
                    this.contentWidth > this.marqueeWidth || this.contentWidth > viewportWidth

                if (shouldAnimateNew && !shouldAnimate) {
                    // Start animation
                    this.startAnimation()
                    shouldAnimate = true

                    // Remove existing clones
                    this.removeClones()

                    // Recreate clones based on the updated marquee width
                    // const logoItem = this.marqueeElement.querySelector('.js-logo-item')
                    // const logoWidth = logoItem.offsetWidth
                    const clonesNeeded = Math.ceil(this.marqueeWidth / this.logoWidth) + 1

                    this.cloneCount = clonesNeeded

                    this.clone = this.marqueeElement.cloneNode(true)
                    for (let i = 0; i < clonesNeeded; i++) {
                        if (this.marqueeElement.children[i]) {
                            this.clone.appendChild(this.marqueeElement.children[i].cloneNode(true))
                        }
                    }

                    this.marqueeElement.appendChild(this.clone)
                } else if (!shouldAnimateNew && shouldAnimate) {
                    // Stop animation
                    this.stopAnimation()
                    shouldAnimate = false

                    // Remove existing clones
                    this.removeClones()
                }
            },

            removeClones() {
                this.marqueeElement.style.transform = 'translate3d(0px,0,0)'
                this.posX = 0
                if (this.clone) {
                    this.marqueeElement.removeChild(this.clone)
                    this.clone = null
                    this.cloneCount = 0
                }
            }
        }
    }

    window.obaia.Components.accordion = (options) => {
        return {
            id: options.id ?? null,
            get expanded() {
                return this.active === this.id
            },
            set expanded(value) {
                this.active = value ? this.id : null
            }
        }
    }

    window.obaia.Components.swiper = (options) => {
        return {
            swiperConstance: '',
            init() {
                let initialSlideIndex = 0
                if (options.initialSlide !== '' && typeof options.initialSlide === 'string') {
                    initialSlideIndex = Array.prototype.indexOf.call(this.$el.querySelectorAll('.swiper-slide'), this.$el.querySelector('#' + options.initialSlide))
                }
                const thisElem = this.$el
                window.addEventListener('load', () => {
                    this.swiperConstance = new Swiper(thisElem, {
                        modules: [Navigation, Pagination, Mousewheel, EffectCreative, Autoplay, FreeMode],
                        autoplay: options.autoplay ?? false,
                        speed: options.speed ?? 300,
                        freeMode: options.freeMode ?? false,
                        // cssMode: options.cssMode ?? false,
                        grabCursor: options.grabCursor ?? false,
                        loop: options.loop ?? false,
                        autoHeight: options.autoHeight ?? false,
                        slidesPerView: options.slidesPerView ?? 1,
                        centeredSlides: options.centeredSlides ?? false,
                        spaceBetween: options.spaceBetween ?? 16,
                        initialSlide: options.initialSlide ? initialSlideIndex : options.centeredSlides ? this.$el.querySelectorAll('.swiper-slide').length / 2 : 0,
                        mousewheel: {
                            forceToAxis: true,
                        },
                        navigation: {
                            prevEl: this.$refs.swiperPrev ?? thisElem.closest('section').querySelector('.js-swiper-button-prev'),
                            nextEl: this.$refs.swiperNext ?? thisElem.closest('section').querySelector('.js-swiper-button-next'),
                        },
                        pagination: options.pagination ? {
                            el: thisElem.closest('section').querySelector('.js-swiper-pagination'),
                            clickable: true,
                            dynamicBullets: options.dynamicBullets ?? true,
                            // renderBullet: function (index, className) {
                            //     let names = []
                            //     thisElem.querySelectorAll('.swiper-slide').forEach((slide) => {
                            //         names.push(slide.getAttribute('data-name'))
                            //     })
                            //     return '<li class="cursor-pointer antialiased ' + className + '">' + names[index] + '</li>';
                            // }
                        } : {},
                        breakpoints: {
                            ...options.breakpoints
                        },
                    })
                })
            }
        }
    }

    // window.obaia.Components.listbox = function (e) {
    //     return {
    //         init() {
    //             this.optionCount = this.$refs.listbox.children.length,
    //                 this.$watch("activeIndex", (e => {
    //                     this.open && (null !== this.activeIndex ? this.activeDescendant = this.$refs.listbox.children[this.activeIndex].id : this.activeDescendant = "")
    //                 }
    //                 ))
    //         },
    //         activeDescendant: null,
    //         optionCount: null,
    //         open: !1,
    //         activeIndex: null,
    //         selectedIndex: 0,
    //         get active() {
    //             return this.items[this.activeIndex]
    //         },
    //         get [e.modelName || "selected"]() {
    //             return this.items[this.selectedIndex]
    //         },
    //         choose(e) {
    //             this.selectedIndex = e,
    //                 this.open = !1
    //         },
    //         onButtonClick() {
    //             this.open || (this.activeIndex = this.selectedIndex,
    //                 this.open = !0,
    //                 this.$nextTick((() => {
    //                     this.$refs.listbox.focus(),
    //                         this.$refs.listbox.children[this.activeIndex].scrollIntoView({
    //                             block: "nearest"
    //                         })
    //                 }
    //                 )))
    //         },
    //         onOptionSelect() {
    //             null !== this.activeIndex && (this.selectedIndex = this.activeIndex),
    //                 this.open = !1,
    //                 this.$refs.button.focus()
    //         },
    //         onEscape() {
    //             this.open = !1,
    //                 this.$refs.button.focus()
    //         },
    //         onArrowUp() {
    //             this.activeIndex = this.activeIndex - 1 < 0 ? this.optionCount - 1 : this.activeIndex - 1,
    //                 this.$refs.listbox.children[this.activeIndex].scrollIntoView({
    //                     block: "nearest"
    //                 })
    //         },
    //         onArrowDown() {
    //             this.activeIndex = this.activeIndex + 1 > this.optionCount - 1 ? 0 : this.activeIndex + 1,
    //                 this.$refs.listbox.children[this.activeIndex].scrollIntoView({
    //                     block: "nearest"
    //                 })
    //         },
    //         ...e
    //     }
    // }
    // ,
    window.obaia.Components.menu = function (e = {
        open: !1
    }) {
        return {
            init() {
                this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]')),
                    this.$watch("open", (() => {
                            this.open && (this.activeIndex = -1)
                        }
                    ))
            },
            activeDescendant: null,
            activeIndex: null,
            items: null,
            open: e.open,
            focusButton() {
                this.$refs.button.focus()
            },
            onButtonClick() {
                this.open = !this.open,
                this.open && this.$nextTick((() => {
                        this.$refs["menu-items"].focus()
                    }
                ))
            },
            onButtonEnter() {
                this.open = !this.open,
                this.open && (this.activeIndex = 0,
                    this.activeDescendant = this.items[this.activeIndex].id,
                    this.$nextTick((() => {
                            this.$refs["menu-items"].focus()
                        }
                    )))
            },
            onArrowUp() {
                if (!this.open)
                    return this.open = !0,
                        this.activeIndex = this.items.length - 1,
                        void (this.activeDescendant = this.items[this.activeIndex].id);
                0 !== this.activeIndex && (this.activeIndex = -1 === this.activeIndex ? this.items.length - 1 : this.activeIndex - 1,
                    this.activeDescendant = this.items[this.activeIndex].id)
            },
            onArrowDown() {
                if (!this.open)
                    return this.open = !0,
                        this.activeIndex = 0,
                        void (this.activeDescendant = this.items[this.activeIndex].id);
                this.activeIndex !== this.items.length - 1 && (this.activeIndex = this.activeIndex + 1,
                    this.activeDescendant = this.items[this.activeIndex].id)
            },
            onClickAway(e) {
                if (this.open) {
                    const t = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
                    this.open = !1,
                    e.target.closest(t) || this.focusButton()
                }
            }
        }
    }

    window.obaia.Components.popoverGroup = function () {
        return {
            __type: "popoverGroup",
            init() {
                let e = t => {
                        document.body.contains(this.$el) ? t.target instanceof Element && !this.$el.contains(t.target) && window.dispatchEvent(new CustomEvent("close-popover-group", {
                            detail: this.$el
                        })) : window.removeEventListener("focus", e, !0)
                    }
                ;
                window.addEventListener("focus", e, !0)
            }
        }
    }

    window.obaia.Components.popover = function ({open: e = !1, focus: t = !1} = {}) {
        const i = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
        return {
            __type: "popover",
            open: e,
            init() {
                t && this.$watch("open", (e => {
                        e && this.$nextTick((() => {
                                !function (e) {
                                    const t = Array.from(e.querySelectorAll(i));
                                    !function e(i) {
                                        void 0 !== i && (i.focus({
                                            preventScroll: !0
                                        }),
                                        document.activeElement !== i && e(t[t.indexOf(i) + 1]))
                                    }(t[0])
                                }(this.$refs.panel)
                            }
                        ))
                    }
                ));
                let e = i => {
                        if (!document.body.contains(this.$el))
                            return void window.removeEventListener("focus", e, !0);
                        let n = t ? this.$refs.panel : this.$el;
                        if (this.open && i.target instanceof Element && !n.contains(i.target)) {
                            let e = this.$el;
                            for (; e.parentNode;)
                                if (e = e.parentNode,
                                e.__x instanceof this.constructor) {
                                    if ("popoverGroup" === e.__x.$data.__type)
                                        return;
                                    if ("popover" === e.__x.$data.__type)
                                        break
                                }
                            this.open = !1
                        }
                    }
                ;
                window.addEventListener("focus", e, !0)
            },
            onEscape() {
                this.open = !1,
                this.restoreEl && this.restoreEl.focus()
            },
            onClosePopoverGroup(e) {
                e.detail.contains(this.$el) && (this.open = !1)
            },
            toggle(e) {
                this.open = !this.open,
                    this.open ? this.restoreEl = e.currentTarget : this.restoreEl && this.restoreEl.focus()
            }
        }
    }

    window.obaia.Components.radioGroup = function ({initialCheckedIndex: e = 0} = {}) {
        return {
            value: void 0,
            active: void 0,
            init() {
                let t = Array.from(this.$el.querySelectorAll("input"));
                this.value = t[e]?.value;
                for (let e of t)
                    e.addEventListener("change", (() => {
                            console.log(e.value);
                            this.active = e.value
                        }
                    )),
                        e.addEventListener("focus", (() => {
                                this.active = e.value
                            }
                        ));
                window.addEventListener("focus", (() => {
                        console.log("Focus change"),
                        t.includes(document.activeElement) || (console.log("HIT"), this.active = void 0
                        )
                    }
                ), !0)
            }
        }
    }

// window.obaia.Components.tabs = function () {
//     return {
//         selectedIndex: 0,
//         onTabClick(e) {
//             if (!this.$el.contains(e.detail))
//                 return;
//             let t = Array.from(this.$el.querySelectorAll('[x-data^="Components.tab("]'))
//                 , i = Array.from(this.$el.querySelectorAll('[x-data^="Components.tabPanel("]'))
//                 , n = t.indexOf(e.detail);
//             this.selectedIndex = n,
//                 window.dispatchEvent(new CustomEvent("tab-select", {
//                     detail: {
//                         tab: e.detail,
//                         panel: i[n]
//                     }
//                 }))
//         },
//         onTabKeydown(e) {
//             if (!this.$el.contains(e.detail.tab))
//                 return;
//             let t = Array.from(this.$el.querySelectorAll('[x-data^="Components.tab("]'))
//                 , i = t.indexOf(e.detail.tab);
//             "ArrowLeft" === e.detail.key ? this.onTabClick({
//                 detail: t[(i - 1 + t.length) % t.length]
//             }) : "ArrowRight" === e.detail.key ? this.onTabClick({
//                 detail: t[(i + 1) % t.length]
//             }) : "Home" === e.detail.key || "PageUp" === e.detail.key ? this.onTabClick({
//                 detail: t[0]
//             }) : "End" !== e.detail.key && "PageDown" !== e.detail.key || this.onTabClick({
//                 detail: t[t.length - 1]
//             })
//         }
//     }
// }

// window.obaia.Components.tab = function (e = 0) {
//     return {
//         selected: !1,
//         init() {
//             let t = Array.from(this.$el.closest('[x-data^="Components.tabs("]').querySelectorAll('[x-data^="Components.tab("]'));
//             this.selected = t.indexOf(this.$el) === e,
//                 this.$watch("selected", (e => {
//                     e && this.$el.focus()
//                 }
//                 ))
//         },
//         onClick() {
//             window.dispatchEvent(new CustomEvent("tab-click", {
//                 detail: this.$el
//             }))
//         },
//         onKeydown(e) {
//             ["ArrowLeft", "ArrowRight", "Home", "PageUp", "End", "PageDown"].includes(e.key) && e.preventDefault(),
//                 window.dispatchEvent(new CustomEvent("tab-keydown", {
//                     detail: {
//                         tab: this.$el,
//                         key: e.key
//                     }
//                 }))
//         },
//         onTabSelect(e) {
//             this.selected = e.detail.tab === this.$el
//         }
//     }
// }

// window.obaia.Components.tabPanel = function (e = 0) {
//     return {
//         selected: !1,
//         init() {
//             let t = Array.from(this.$el.closest('[x-data^="Components.tabs("]').querySelectorAll('[x-data^="Components.tabPanel("]'));
//             this.selected = t.indexOf(this.$el) === e
//         },
//         onTabSelect(e) {
//             this.selected = e.detail.panel === this.$el
//         }
//     }
// }